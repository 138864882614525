@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  /* color: #333; */
  font-size: large;
}
.navbar {
  position: sticky;
  top:0;
  z-index: 3;
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  /* border-bottom: 4px solid #5bc8ff; */
    /* border-radius: 5px; */
box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
0px 1px 2px 0px rgba(0,0,0,0.24);
  background-color: white;

}
.menu_nav {
  display: none;
  color: #5bc8ff;
}
h1 {
  font-size: xx-large;
  color: #5bc8ff;
}
h2 {
  font-size: x-large;
  color: #5bc8ff;
}
.navbar h1 {
  color: #5bc8ff;
  margin-left: 50px;
  user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}
.navbar .links {
  margin-left: auto;
  margin-right: 50px;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  /* color: #333; */
}
.normal_link {
  color: #333;
}
a {
  text-decoration: none;
}
.underline-anim {
  display: inline-block;
  padding-bottom: 0.25rem; /* defines the space between text and underline */
  position: relative;
}

.underline-anim::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #5bc8ff;
  transition: width 0.25s ease-out;
}

.underline-anim:hover::before{
  width: 100%;
}
/* TODO: choose with or without */
/* .navbar a:hover {
  color: #5bc8ff;
} */
.home {
  max-width: 100%;
  max-height: 80%;
  display: flex;
  justify-content:center;
  flex-direction: row;
  height: 500px;
  margin: 40px auto;
  padding: 20px;
}
.description {
  background-color: white;
  flex: 1;
  /* margin-right: 20px; */
  /* margin-top: 100px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.aboutMeContainer {
  max-width: 100%;
  /* max-height: 80%; */
  display: flex;
  justify-content:center;
  flex-direction: row;
  height: auto;
  background-color: white;
  /* margin: 40px auto; */
  padding: 20px;
  padding-top: 0px;
}
.aboutMe {
  background-color: white;
  flex: 1;
  margin-right: 20px;
  padding: 20px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items:flex-end;
  justify-content: center;
}
.aboutmeContent {
  background-color: white;
  max-width: 100%;
  margin-left: 30%;
  align-items: center;
  justify-content: center;
}

.aboutmeContent p, h1 {
  margin: 10px;
  /* margin-left: 0px; */
}
.content {
  align-items: flex-start;
}
.photo {
  margin-top: 100px;
  background-color: white;
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .photo img {
  opacity:0.75;
  transition: 0.3s;
}
.photo img:hover{
  opacity:1;
  transition: 0.3s;
} */
.photo img {
  transition: 0.5s ease;
  filter: grayscale(100%);
-webkit-filter: grayscale(100%);
-webkit-transition: 1s ease;
  }
  
.photo img:hover{
  filter: grayscale(0%);
filter: gray;
-webkit-filter: grayscale(0%);
filter: none;
transition: 1s ease;
  /* -webkit-transform: scale(0.98);
  -ms-transform: scale(0.98);
  transform: scale(0.98); */
  transition: 0.5s ease-in-out;
  }

img {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
0px 1px 2px 0px rgba(0,0,0,0.24);
  object-fit: contain;
  max-width:100%;
  max-height:100%;  
}
.me {
  max-width:70%;
  max-height:70%;  
}
.footer {
  height: 10%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  background-color: #5bc8ff;
}
.footer p {
  font-size: small;
  color: white;
}
.social-container {
  margin: 10px;
}
.social-container a {
  padding: 3px;
}
.social {
  color: #333;
  transition: 0.1s;
  cursor: pointer;
  z-index: 2;
  position: relative;
}
.social:hover {
  color: #5bc8ff;
  transition: 0.2s;
}

.accordion-panel {
  height: 30%;
}
.accordion {
  /* Apply transition to the base state */
  transition: box-shadow 0.5s ease !important;
}
.accordion_icon {
  color: #333; /* Default color */
  transition: color 0.3s ease;
}
.accordion:hover .icon {
  color: #5bc8ff; /* Color when hovered */
}

.accordion:hover {
  /* Add your hover effects */
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.19),
              0px 6px 6px 0px rgba(0, 0, 0, 0.23);
}

.skillsContent {
  max-width: 100%;
  /* max-height: 80%; */
  display: flex;
  align-items:center;
  flex-direction: column;
  height: auto;
  margin: 40px auto;
  padding: 20px;
  /* padding-top: 100px; */
}
.skillsContent h1 {
  margin-top: 150px;
}
.skills {
  width: 100%;
  /* height: 80%; */
  display: flex;
  flex-direction: row;
  background-color: white;
  align-items:center;
  justify-content: center;
  padding-top: 50px;
}
.skill {
  max-width: 300px;
  min-height: 250px;
  flex:1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-right: 20px;
  margin-left: 20px;
  background-color: white;
  transition: 0.5s ease;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
0px 1px 2px 0px rgba(0,0,0,0.24);

}
.skill h2{
  color: #5bc8ff;
}

.skill li{
  color: #333;
}
.skill:hover {
  transition: 0.5s ease;
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.19),
0px 6px 6px 0px rgba(0,0,0,0.23);
}
/* .skill:hover h2{
  color:white;
}
.skill:hover li{
  color: white;
} */
.skill p, h2 {
  padding: 20px;
}
.skill h2 {
  padding-top: 20px;
  text-align: center;
}

.contacts {
  max-width: 40%;
  height: auto;
  display: flex;
  justify-content:center;
  flex-direction: column;
  /* height: 100px; */
  background-color: white;
  margin: 40px auto 80px;
  padding: 20px;
  align-items: center;
}
.contacts h1{
  margin: 20px;
  margin-top: 150px;
}
.contact_form {
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
}
.two_fields_row {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
label {
  /* font-size: smaller; */
  width: 100%;
}
input[type=text], textarea {
  padding: 12px;
  margin: 5px;
  margin-left: 0px;
  width: 90%;
  resize: vertical;
  outline: none;
  /* overflow: hidden; */
  border: 2px solid #dedede;
-webkit-transition: 0.5s;
  transition: 0.5s;
}
.file-input-label {
  /* position: relative;
  display: inline-block; */
  border: 2px solid #5bc8ff;
}

.select-file-button {
  position: relative;
  display: inline-block;
  padding: 12px;
  margin: 5px;
  margin-left: 0px;
  width: 90%;
  resize: vertical;
  outline: none;
  /* overflow: hidden; */
  border: 2px solid #dedede;
-webkit-transition: 0.5s;
  transition: 0.5s;
}
.select-file-button:hover {
  border: 2px solid #5bc8ff;
}

.file-input {
  display:none;
  opacity: 0;
  cursor: pointer;
}
.file-name-text {
  margin-left: 10px;
  color: #888;
  font-size: 14px;
}

input:focus, textarea:focus, input:hover, textarea:hover {
  border: 2px solid #5bc8ff;
}
input[type=submit] {
  width: fit-content;
  color: white;
  border: 2px solid #5bc8ff;
  background-color: #5bc8ff;
  text-decoration: none;
  padding: 6px;
  cursor: pointer;
}
input[type=submit]:hover{
  width: fit-content;
  color: #333;
  background-color: white;
}

input, label, textarea {
  display:block;
}
.form_but_row {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_form_row {
  padding: 10px;
/* box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
0px 1px 2px 0px rgba(0,0,0,0.24); */
}
.or {
  margin-bottom: 20px;
}
.myProjects {
  max-width: 60%;
  max-height: auto;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  margin: 40px auto;
  padding-top: 100px;
  padding: 20px;
}
.otherProjects {
  max-width: 60%;
  max-height: auto;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  margin: 40px auto;
  padding: 20px;
}
.project_grid {
  max-width: 100%;
  display: flex;
  /* background-color: pink; */
  /* max-width: 100%; */
  justify-content:center;
  align-items: center;
  flex-direction: row;
}
.project_grid_column{
  max-width: 100%;
  display: flex;
  flex: 1;
  justify-content:center;
  /* background-color: #5bc8ff; */
  /* align-items: center; */
  flex-direction: column;
}
.project_grid_column_l{
  align-items: end;
}
.project_grid_column_r{
  align-items:start;
}
.other_project {
  background-color: white;
  height: 50%;
  width: 65%;
  /* animation feature */
  position:relative;
  background:white;
  font-size:28px;
  transition:all 1s;
  margin-top: 0;

  margin: 40px;
  margin-bottom: 0px;
  min-height: 175px;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
0px 1px 2px 0px rgba(0,0,0,0.24);

  &:after,&:before{
    content:" ";
    width:10px;
    height:10px;
    position:absolute;
    border :0px solid #fff;
    transition:all 0.5s;
    }
  &:after{
    top:-1px;
    left:-1px;
    border-top:3px solid #5bc8ff;
    border-left:3px solid #5bc8ff;
  }
  &:before{
    z-index: 1;
    bottom:-1px;
    right:-1px;
    border-bottom:3px solid #5bc8ff;
    border-right:3px solid #5bc8ff;
  }
  &:hover{
    &:before,&:after{
      width:100%;
      height:100%;
    }
  }
}

.other_project .projectName{
  width: 500px;
}
.other_project p, h1, h2 {
  padding: 3px;
}
.myProjects h1 {
  margin-top: 150px;
} 
.myProjects p, h1, h2 {
  padding: 3px;
}
.project {
  margin: 40px;
  margin-bottom: 0px;
  /* padding-top: 40px; */
  /* padding-bottom: 40px; */
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
0px 1px 2px 0px rgba(0,0,0,0.24);
  transition: 0.3s;
}

.project:hover {
  box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.19),
0px 6px 6px 0px rgba(0,0,0,0.23);
  transition: 0.5s;
}
/* .project:hover h2{
  color: white;
} */
.projectName {
  margin: 20px;
  flex: 1.25;
}
.project_icon {
  color: #333;
  margin-right: 10px;
  cursor: pointer;
}
.project:hover .project_icon:hover {
  color: #5bc8ff;
}
.projectName .links{
  margin-top: 20px;
  margin-left: 5px;
}
.blue {
  color: #5bc8ff;
  font-weight: bolder;
}
.projectDescription {
  margin: 20px;
  flex: 1.75;
}

.nav_but:hover {
  color: white;
  border: 2px solid #5bc8ff;
  background-color: #5bc8ff;
  /* box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.19),
0px 6px 6px 0px rgba(0,0,0,0.23); */
}

.nav_but {
  border: 2px solid #5bc8ff;
  color: #333;
  background-color: white;
  /* box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.12),
  0px 1px 2px 0px rgba(0,0,0,0.24); */
  transition: 0.2s;
}
/* .project_but {
  color: white;
  background-color: #5bc8ff;

  transition: 0.2s;
  text-decoration: none;
  padding: 6px;
} */

.responsive {
  display: block;
}
@media (min-width: 850px) {

  .normal_link {
    display: inline-block;
    margin-top: 5px;
  }
  /* .navbar{
    flex-direction: row;
  } */
}

@media (max-width: 290px) {
  .navbar {
    flex-direction: column;
  }
  .underline-anim {
    display: none;
  }
  .navbar h1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .navbar a {
    margin-left: 0px;
    text-decoration: none;
    padding: 6px;
    /* color: #333; */
  }
  .navbar .links {
    display: flex;
    margin-right: auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  /* .links a {
    margin: 10px;
  } */
  .aboutMeContainer {
    flex-direction: column;
  }

  .aboutmeContent {
    margin-left: 0;
  }
  .photo {
    margin-top: 0px;
  }
  .photo img {
    transition: 0.5s ease;
    filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -webkit-transition: 1s ease;
    }
  .me {
    max-width: 80%;
    max-height:80%; 
  }
  .skills h1{
    margin-top: 50px;
  }
  .skills {
    flex-direction: column;
    padding-top: 0px;
  }
  .skill {
    width: 100%;
    margin: 40px;
    margin-bottom: 0px;
  }
  .skillsContent {
    padding-top: 0px;
  }
  .myProjects {
    max-width: 100%;
  }
  .myProjects h1 {
    margin-top: 50px;
  }
  .project {
    flex-direction: column;
  }
  .project_grid {
    flex-direction: column;
  }
  .other_project {
    width: 100%;
  }
  .other_project .projectName{
    width: 200px;
  }
  .project_grid_column_l{
    align-items:center;
  }
  .project_grid_column_r{
    align-items:center;
  }
  .contacts {
    max-width: 80%;
  }

}
@media (min-width: 291px) and (max-width: 849px) {
  /* .menu_nav {
    display: block;
  }
  .normal_link {
    display: none;
  } */
  .underline-anim {
    display: none;
  }
  .navbar{
    flex-direction: row;
  }
  .navbar h1 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .navbar a {
    margin-left: 0px;
    text-decoration: none;
    padding: 6px;
    /* color: #333; */
  }
  .navbar .links {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
  }
  /* .links a {
    margin: 10px;
  } */
  .aboutMeContainer {
    flex-direction: column;
  }

  .aboutmeContent {
    margin-left: 0;
  }
  .photo {
    margin-top: 0px;
  }
  .photo img {
    transition: 0.5s ease;
    filter: grayscale(0%);
  -webkit-filter: grayscale(0%);
  -webkit-transition: 1s ease;
    }
  .me {
    max-width: 80%;
    max-height:80%; 
  }
  .skills h1{
    margin-top: 50px;
  }
  .skills {
    flex-direction: column;
    padding-top: 0px;
  }
  .skill {
    width: 100%;
    margin: 40px;
    margin-bottom: 0px;
  }

  .skillsContent {
    padding-top: 0px;
  }
  .myProjects {
    max-width: 100%;
  }
  .myProjects h1 {
    margin-top: 50px;
  }
  .project {
    flex-direction: column;
  }
  .project_grid {
    flex-direction: column;
  }
  .other_project {
    width: 100%;
  }
  .other_project .projectName{
    width: 200px;
  }
  .project_grid_column_l{
    align-items:center;
  }
  .project_grid_column_r{
    align-items:center;
  }
  .contacts {
    max-width: 80%;
  }
}